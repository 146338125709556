<template>
  <div class="employer-root-container">
    <div class="employer-header-container">
      <div class="employer-header-left-container">
        <img @click="home" :src="icons.homeWhite" style="cursor: pointer;" />
        <span class="employer-header-title" style="margin-left:60px;">首页</span>
        <span class="employer-header-title" v-if="company" style="margin:0 20px;">|</span>
        <span class="employer-header-title" v-if="company">{{company}}</span>
      </div>
      <img @click="close" :src="icons.upArrow" class="employer-arrow" />
    </div>
    <transition name="fade" mode="out-in">
      <router-view class="employer-router-container" />
    </transition>
  </div>
</template>

<script>
import * as eventHelper from "../../utils/eventHelper";
import * as icon from "../../common/icon";
export default {
  data() {
    return {
      icons: icon,
      company: null,
    };
  },
  mounted() {
    eventHelper.addEventListener("changeTitle", this.changeTitle);
  },
  beforeDestroy() {
    eventHelper.removeEventListener("changeTitle", this.changeTitle);
  },
  methods: {
    home() {
      if (this.$router.currentRoute.name == "employer-home") {
        return;
      }
      this.$router.push({
        name: "employer-home",
      });
    },
    close() {
      this.$router.push({ name: "dashboard" });
    },
    changeTitle(title) {
      this.company = title;
    },
  },
};
</script>

<style scoped>
.employer-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.employer-header-container {
  background-color: #313f4a;
  height: 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 50px;
  padding-left: 50px;
}
.employer-header-left-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.employer-arrow {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.employer-header-title {
  color: #fff;
  font-size: 15px;
}
.employer-router-container {
  flex: 1;
  overflow: hidden;
}
</style>